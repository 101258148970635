import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  OnChanges,
  Optional,
  Output,
  Self,
  ViewChild,
  ChangeDetectorRef,
  AfterContentChecked,
  ViewChildren,
  QueryList,
} from "@angular/core";
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NgControl,
  Validators,
} from "@angular/forms";
import { debounceTime, distinctUntilChanged, takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { MatFormFieldControl } from "@angular/material/form-field";
import { FocusMonitor } from "@angular/cdk/a11y";
import { coerceBooleanProperty } from "@angular/cdk/coercion";
import { MatSelect } from "@angular/material/select";

import {
  RequestAttribute,
  RequestParams,
} from "../../../../../utils/models/http.interface";
import { HttpService } from "../../../../service/http/http.service";
import { environment } from "../../../../../environments/environment";
import { ConfigApiDynamicTable } from "../../../../../utils/models/table.interface";
import { IconsSelectInfiniteScroll } from "src/utils/models/common.interface";
import { MatOption } from "@angular/material/core";
import { MatCheckbox } from "@angular/material/checkbox";

export function validateCounterRange(c: FormControl) {
  const err = {
    rangeError: {
      given: c.value,
    },
  };
  return c.value === undefined || c.value === null ? err : null;
}

@Component({
  // tslint:disable-next-line: component-selector
  selector: "select-infinite-scroll-search",
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: SelectInfiniteScrollSearchComponent,
    },
  ],
  templateUrl: "./select-infinite-scroll-search.component.html",
  styleUrls: ["./select-infinite-scroll-search.component.scss"],
})
export class SelectInfiniteScrollSearchComponent
  implements
    OnInit,
    OnChanges,
    MatFormFieldControl<any>,
    ControlValueAccessor,
    AfterViewInit,
    OnDestroy,
    Validators
    
{
  private readonly apiUrl: string = environment.apiUrl;

  stateChanges = new Subject<void>();
  labels = [];
  allSelected = false;

  @Input()
  set options(arr: any[]) {
    if (arr) {
      this._options = [...arr];
      this._options = this._options.filter((o) => !!o);
      this._oprtionOriginal = [...arr];
      this._oprtionOriginal = this._oprtionOriginal.filter((o) => !!o);
      if (this.paramValue) {
        this.getFirstRequest();
      }
    }
  }

  @Input()
  set value(obj: any | null) {
    this._value = obj;
  }
  get value(): any | null {
    if (this._value) {
      return this._value;
    } else {
      return "teste";
    }
  }

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  get errorState() {
    const controlDirectiveAux: any = this.controlDirective;
    let parent = controlDirectiveAux?._parent;
    while (parent?._parent) {
      parent = parent._parent;
    }
    if (parent?.submitted) {
      return (
        !!this.controlDirective?.invalid && !this.controlDirective.disabled
      );
    } else {
      return !!this.controlDirective?.invalid && this.touched;
    }
  }
  @Input()
  get placeholder() {
    return this._placeholder;
  }
  set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next();
  }

  get empty() {
    return !this._value;
  }

  @HostBinding("class.floating")
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.parts?.disable() : this.parts?.enable();
    this.stateChanges.next();
  }

  constructor(
    public httpService: HttpService,
    private readonly fm: FocusMonitor,
    private readonly elRef: ElementRef<HTMLElement>,
    @Optional() @Self() public controlDirective: NgControl,
    private readonly cdRef: ChangeDetectorRef
  ) {
    this.sleep(300).then(
      ()=>{
        if(this.haveInitialRequest){
          this.getFirstRequest();
        }
      }
    )
    fm.monitor(elRef.nativeElement, true).subscribe((origin) => {
      this.focused = !!origin;
      this.stateChanges.next();
    });
    if (this.controlDirective) {
      this.controlDirective.valueAccessor = this;
    }
    
  }

  getFirstRequest() {
    if (!this.internalFilter && !!this.endpoint) {
      const valueParams = this.paramValue || this._value;
      const endpointRequest =  this.endpoint;
      if (this.returnVariable) {
        this.handleVariable(endpointRequest, valueParams);
      } else {
        this.handleWithoutVariable(endpointRequest, valueParams);
      }
    }
  }

  static nextId = 0;

  _options;
  _oprtionOriginal;

  mapTablesPostFiltersGet = {
    '/alert-histories/uniqueAttributes': true
  }

  @Input() internalFilter = true;
  @Input() interface = "";
  @Input() key = "keyDefault";
  @Input() getSelectedOption: any;
  @Input() disabledOptions: any;
  @Input() configApi: ConfigApiDynamicTable;
  @Input() endpoint: string;
  @Input() label: string;
  @Input() multiple: boolean;
  @Input() enableInfScroll = true;
  @Input() labelShow: string;
  @Input() labelShowPref: string;
  @Input() labelShowSuf: string;
  @Input() labelShowSearch: string;
  @Input() parts;
  @Input() hasCount: boolean;
  @Input() returnVariable: string = undefined;
  @Input() alphabeticalOrder = false;
  @Input() paramValue: string;
  @Input() selectAll: string;
  @Input() triggerSelectAll: string = 'Todos seleccionados';
  @Input() iconsButtons: IconsSelectInfiniteScroll;
  @Input() useCompareMultiple = false
  @Input() isPost = false;
  @Input() preventInfinite = false;
  @Input() idInfinite = ''
  @Input() hasAllSelect = false


  @Input() inputSearch: string = undefined;
  @Input() filterSearch = true;
  @Input() hasEmptyOption: false;
  @Input() isTableFilter = false;
  @Input() last = false;
  @Input() filterName: string;
  @Input() filterAllOptionsName = "Todas las opciones";
  @Input() isDynamicTable = false;
  @Input() haveInitialRequest = true;
  @Input() max = 1
  @Input() postRequest = false
  @Input() startAllSelected=false
  @Output() selectionChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectionTouched: EventEmitter<any> = new EventEmitter<any>();
  @Output() optionEmmiter: EventEmitter<any> = new EventEmitter<any>();
  @Output() inputSearchEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() clearFilter: EventEmitter<any> = new EventEmitter<any>();
  @Output() filterEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() openedSelectEmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() applySelectedValues: EventEmitter<any> = new EventEmitter<any>();
  @Output() closeMenuEmmiter: EventEmitter<any> = new EventEmitter<any>();
  @Output() cleanButtonEmitter: EventEmitter<any> = new EventEmitter<any>();
  @Output() typed: EventEmitter<any> = new EventEmitter<any>();
  @Input() postData = null
  @Input() outputRequest = false
  // @ViewChildren('selectAllCheckbox') selectAllCheckbox: QueryList<MatOption>;
  // @ViewChildren('selectCheckbox') selectCheckbox: QueryList<MatOption>;

  @ViewChild('selectAllCheckbox') selectAllCheckbox: MatCheckbox;


  @ViewChild("multiSelect") multiSelect: MatSelect;

  complete = false;
  firstValue: any;
  touched = false;

  @Input() attributes: RequestAttribute[] = [];
  _value: any = [];
  _required = false;
  _placeholder: string;

  ngControl: NgControl = null;
  focused = false;

  @HostBinding()
  id = `example-tel-input-${SelectInfiniteScrollSearchComponent.nextId++}`;
  @HostBinding("attr.aria-describedby") describedBy = "";
  private _disabled = false;
  @Input() disabledAux = false;
  @Input() disableClass = false
  // tslint:disable-next-line: variable-name
  private readonly _onDestroy = new Subject<void>();

  public formAux: FormControl = new FormControl();

  loading = false;
  requestParams: RequestParams = {
    sort: "",
    order: "",
    page: 1,
    limit: 20,
  };

  initialData: any = [];
  restore = true;

  AttLabs = false;
  atualizarLabelShowAtt = false;
  atualizarLabelShowPrefAtt = false;
  atualizarLabelShowSufAtt = false;
  preventRequest = false;
  i = 0;
  initialRequestOptions:any = null

  filtrar() {
    this.filterEvent.emit(this._value);
    this.restore = false;
    this.multiSelect.toggle();
  }

  limpar() {
    this.clearOptions();
    this.clearFilter.emit();
    this.multiSelect.toggle();
  }

  clearOptions() {
    this.allSelected = false;
    this.writeValue(this.multiple ? [] : '');
    this.stateChanges.next();
    this.restore = false;
    this.complete = false;
    this.cdRef.detectChanges();
    this.propagateChange(this._value);
    this.selectionChange.emit(this._value);
    this.cleanButtonEmitter.emit(true)
  }

  handleAplly() {
    const applied = {
      values: this._value,
      field: this.key
    }
    this.applySelectedValues.emit(applied)
    this.multiSelect.toggle();
  }

  validate(c: AbstractControl): { [key: string]: any } {
    // Here we call our static validator function
    return this.required ? Validators.required(c) : null;
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(" ");
  }

  onContainerClick(event: MouseEvent) {
    if ((event.target as Element).tagName.toLowerCase() !== "div") {
      this.elRef.nativeElement.querySelector("div").focus();
    }
  }

  public setDisabledState(isDisabled: boolean): void {
    this._disabled = isDisabled;
    this.disabledAux = isDisabled;
  }

  writeValue(value: any) {
    this._value = value;
    this.cdRef.detectChanges();
  }

  propagateChange = (_: any) => {
  };

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() {
  }

  ngOnDestroy() {
    this.stateChanges.complete();
  }

  ngAfterViewInit(): void {
    // if(this._value[0] !== undefined && this._value.length === this._options.length) {
    //   this.writeValue(['all'])
    // }
    this.controlDirective?.valueChanges?.subscribe(() => {
      this.setTouched();
    });

    if (this.isTableFilter) {
      this.multiSelect.openedChange.subscribe((value) => {
        if (value) {
          this.initialData = this._value;
        }
        if (!value && this.restore) {
          this._value = this.initialData;
          this.propagateChange(this._value);
        }
        this.restore = true;
      });
    }

    this.openedSelectInfinite();
  }

  select(e) {
    let newStatus = true;
    this.multiSelect.options.forEach((item: MatOption) => {
      if (!item.selected && item.value) {
        newStatus = false;
      }
    });
    this.allSelected = newStatus;
    this.propagateChange(this._value);
    this.selectionChange.emit(this._value);
  }

  clickAllOpt(selectAllOptionInit = false) {
    this.preventRequest = true
    const val = this._value.indexOf("allOpt") !== -1 ? [...this._options, "allOpt"] : [];
    this.writeValue(val);
  }

  trackByArray(index: number): any {
    return index
  }

  setTouched() {
    this.touched = true;
    this.selectionTouched.emit();
    this.stateChanges.next();
  }

  infiniteScroll(event) {
    if (!this.loading && this.enableInfScroll && !this.internalFilter && !this.preventRequest &&  !this.preventInfinite) {
      this.loading = true;
      this.requestParams.order = this.alphabeticalOrder ? "asc" : "";
      this.requestParams.sort = this.alphabeticalOrder ? this.labelShowSearch : "";
      this.requestParams.page++;
      this.complementsInfiniteScroll();
    }
    this.preventRequest = false;
  }

  complementsInfiniteScroll() {
    const attributesRequest = this.attributes.filter((a) => a.value);
    if (!this.complete) {
      this.complementsInfiniteScroll1(attributesRequest);
    }
  }
  complementsInfiniteScroll1(attributesRequest) {
    if (this.endpoint && !this.preventRequest) {

      if(this.isPost && !this.mapTablesPostFiltersGet[this.endpoint]){
        let body: any = {}
        let url = `${this.endpoint}?page=${this.requestParams.page}&limit=${this.requestParams.limit}&order=${this.requestParams.order}&sort=${this.requestParams.sort}`
        if(this.attributes.length > 0 && this.attributes[0].value !== '' && !!this.attributes[0].value){
           url += `&${this.attributes[0].param}=${this.attributes[0].value}`
        }

        this.httpService.genericPost(url,body, {}).subscribe((obj: any[] | any) => {
          this.loading = false;
          if (this.hasCount) {
            const index = obj.rows.findIndex((option: any, i: number) => {
              return option?.id === this._options[0]?.id;
            });
            if (index !== -1) {
              obj.rows.splice(index, 1);
            }
            this._options = this._options.concat(obj.rows);
            if (obj.rows.length === 0) {
              this.complete = true;
              this.preventRequest = true;
            }
          } else {
            if(Array.isArray(obj)){
              const index = obj.findIndex((option: any, i: number) => {
                return option?.id === this._options[0]?.id;
              });
              if (index !== -1) {
                obj.splice(index, 1);
              }
              this._options = this._options.concat(obj);
              if (obj.length === 0) {
                this.complete = true;
              }
            }else{
                if(obj[this.labelShowSearch]){
                  this._options = this._options.concat(this.formatResponse(obj[this.labelShowSearch]))
                }
                const arraySetAux = new Set();
                this._options = this._options.filter( item => {
                const duplicate = arraySetAux.has(item.value)
                arraySetAux.add(item.value)
                return !duplicate
                })
              
  
            }
            
          }
          this.optionEmmiter.emit(this._options);
  
        }, (err) => {
          this.loading = false;
  
        });
      }else{
        
      this.httpService.genericGetSelectFiltroScroll<[]>(
        this.endpoint,
        this.requestParams,
        attributesRequest
      )
      .subscribe((obj: any[] | any) => {
        this.loading = false;
        if (this.hasCount) {
          const index = obj.rows.findIndex((option: any, i: number) => {
            return option?.id === this._options[0]?.id;
          });
          if (index !== -1) {
            obj.rows.splice(index, 1);
          }
          this._options = this._options.concat(obj.rows);
          if (obj.rows.length === 0) {
            this.complete = true;
            this.preventRequest = true;
          }
        } else {
          if(Array.isArray(obj)){
            const index = obj.findIndex((option: any, i: number) => {
              return option?.id === this._options[0]?.id;
            });
            if (index !== -1) {
              obj.splice(index, 1);
            }
            this._options = this._options.concat(obj);
            if (obj.length === 0) {
              this.complete = true;
            }
          }else{
            this._options = this._options.concat(this.formatResponse(obj[this.labelShowSearch]))

            const arraySetAux = new Set();
            this._options = this._options.filter( item => {
            const duplicate = arraySetAux.has(item.value)
            arraySetAux.add(item.value)
            return !duplicate
            })

          }
          
        }
        this.optionEmmiter.emit(this._options);

      }, (err) => {
        this.loading = false;

      });
      }
    
    }
  }


  formatResponse(response){
    const auxArray = []
    response.forEach(item => {
      auxArray.push({value: item, friendly: item, check: false})
    })
    return auxArray
  }

 
  ngOnChanges(changes) {
    if (this.inputSearch) {
      this.formAux.setValue(this.inputSearch);
    }
    // if(this._value[0] !== undefined && this._value.length === this._options.length) {
    //   this.writeValue(['all'])
    // }
  }

  atualizarAtt(param, value) {
      if (this.attributes?.length) {
        const pos = this.attributes.findIndex((object) => {
          return object.param === param;
        });
  
        if (pos === -1) {
          this.attributes?.push({
            param: param,
            value: value,
          });
        } else {
          this.attributes[pos].value = value;
        }
        if(this.attributes[pos].value === '' && !this.isPost){
          this.attributes = this.attributes.filter((item) => item.param !== param)
        }
      } else {
        this.attributes?.push({
          param: param,
          value: value,
        });
      }
  }

  ngOnInit() {
    const sleepTime = 300;
    this.formAux.valueChanges
      .pipe(
        takeUntil(this._onDestroy),
        debounceTime(sleepTime),
        distinctUntilChanged()
      )
      .subscribe((subs) => {
        // this.typed.emit(subs)
        this.preventRequest = false;
        this.loading = true;
        this.complete = false;
        if (!this.internalFilter) {
          if (this.labelShowSearch) {
            this.atualizarAtt(this.labelShowSearch, this.formAux.value);
          }
          if (this.labelShow && !this.useCompareMultiple) {
            this.atualizarAtt(this.labelShow, this.formAux.value);
          }
          if (this.labelShowPref) {
            this.atualizarAtt(this.labelShowPref, this.formAux.value);
          }
          if (this.labelShowSuf) {
            this.atualizarAtt(this.labelShowSuf, this.formAux.value);
          }

          this.requestParams.page = 1;
          this.requestParams.order = this.alphabeticalOrder ? "asc" : "";
          this.requestParams.sort = this.alphabeticalOrder
            ? this.labelShowSearch
            : "";
        
          if (this.endpoint) {
            if(this.isPost){
              this.preventInfinite = true
              let url = `${this.endpoint}?page=${this.requestParams.page}&limit=${this.requestParams.limit}&order=${this.requestParams.order}&sort=${this.requestParams.sort}&${this.attributes[0].param}=${this.attributes[0].value}`
              this.httpService
                .genericPostNotData(
                  url,
                  null
                )
                .subscribe(
                  (option: any[] | any) => {
                  
                    if (this.hasCount) {
                      this._options = option.rows 

                    } else {
                      if(option !== null && option !== undefined && Object.keys(option).length !== 0){
                        this._options = this.formatResponse(option[this.labelShowSearch]);
                      }

                    }
                    this.optionEmmiter.emit(this._options);

                    this.inputSearchEmitter.emit(this.formAux.value);
                    this.loading = false;
                  },
                  (err) => {
                    this.loading = false;
                  }
                );
            } else {
              this.preventInfinite = true

            this.httpService
            .genericGetSelectFiltroScroll<[]>(
              this.endpoint,
              this.requestParams,
              this.attributes
            )
            .subscribe(
              (option: any[] | any) => {
                if (this.hasCount) {
                  this._options = option.rows 

                } else {
                  if(option !== null && option !== undefined && Object.keys(option).length !== 0){
                    this._options = this.formatResponse(option[this.labelShowSearch]);

                  }

                }
                this.optionEmmiter.emit(this._options);

                this.inputSearchEmitter.emit(this.formAux.value);
                this.loading = false;
              },
              (err) => {
                this.loading = false;
              }
            );
            }

          } else {
            this.loading = false;
          }
        } else {

          // this.clearOptions()
          if (!!this.formAux.value && this.labelShow) {
            this._options = this._oprtionOriginal.filter((option: string) =>
              option[this.labelShow]
                .toString()
                .toLowerCase()
                .includes(this.formAux.value.toLowerCase())
            );
          } else if (!!this.formAux.value) {
            this._options = this._oprtionOriginal.filter((option: string) =>
              option
                .toString()
                .toLowerCase()
                .includes(this.formAux.value.toLowerCase())
            );
          } else {
            this._options = [...this._oprtionOriginal];
          }

          this.loading = false;
        }
        if (!subs) {
          this.preventInfinite = false
        }
      });


    if (this.haveInitialRequest) {
      this.formAux.setValue("");
    }

  }

  sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  openedSelectInfinite(onChanges?) {
    if (this.isDynamicTable) {
      this.openedSelectEmit.emit(true);
    }
  }

  compare(arr1, arr2) {
    if (arr1 && arr2 && (Array.isArray(arr1) && Array.isArray(arr2))) {
      return (
        arr1.length === arr2.length &&
        arr1.every((element, index) => {
          return element === arr2[index];
        })
      );
    }
    return false;
  }

  compareFn(itemFilter: any) {
    if (itemFilter["check"]) {
      return itemFilter["check"];
    }
  }

  compareMultiple(op1, op2){
      if(op1?.value === op2?.value){
        return true
      }else if( op1  === op2){
        return true
      }
      return false
  }

  returnLabel(op) {
      if (this.labelShowPref && this.labelShow && this.labelShowSuf) {
        return `${op[this.labelShowPref]} ${op[this.labelShow]} ${
          op[this.labelShowPref]
        }`;
      } else if (this.labelShowPref && this.labelShow) {
        return `${op[this.labelShowPref]} ${op[this.labelShow]}`;
      } else if (this.labelShow && this.labelShowSuf) {
        return `${op[this.labelShow]} ${op[this.labelShowPref]}`;
      } else if (this.labelShow) {
        return op[this.labelShow];
      }
    
    return op;
  }

  handleVariable(endpointRequest, valueParams) {
    this.httpService
      .genericGetSelectInfinite<any>(endpointRequest, valueParams)
      .subscribe((object: any[] | any) => {
        this._options.unshift(object);
        if (this.hasCount) {
          this._options = object.rows 
          this.initialRequestOptions = object.rows
        } else {
          this._options = object;
          this.initialRequestOptions = object

        }
        const index = this._options.findIndex((option: any, i: number, options: any[]) => {
          return (option[this.returnVariable] === options[0][this.returnVariable] && i !== 0);
        });
        if (index !== -1) {
          this._options.splice(index, 1);
        }
      });

  }

  handleWithoutVariable(endpointRequest, valueParams) {
    this.httpService
      .genericGetSelectInfinite<any>(endpointRequest, valueParams)
      .subscribe((object: any[] | any) => {
        this.writeValue(object);
        this._options?.unshift(object);
        const index = this._options?.findIndex((option: any, i: number, options: any[]) => {
          return (option.id === options[0].id && i !== 0);
        });
        if (index !== -1) {
          this._options?.splice(index, 1);
        }
      });

  }

  resetValueToFirst() {
    if (this.firstValue) {
      this.writeValue(this.firstValue);
      const index = this._options.findIndex(
        (option) => option.id === this.firstValue.id
      );
      if (index !== -1) {
        this._options.splice(index, 1);
      }
      this._options.unshift(this.firstValue);
    }

  }

  optionTrigger = []
  getValueTrigger(fields) {
    const arrayFields = [];
    // this.assignValueTriggerStarts(fields);
    if(fields) {
      fields?.forEach(field => {
        if (field !== 'all') {
          const mapZone = {
            'amarilla': 'Zona Amarilla',
            'verde': 'Zona Verde',
            'roja': 'Zona Roja'
          }
          let findOpt = fields.find(res => res ? res === field :  field);

          if( Object.keys(mapZone).includes(findOpt)){
            findOpt = mapZone[findOpt]
          }
          arrayFields.push(findOpt && findOpt[this.labelShow] ? findOpt[this.labelShow] : findOpt && !findOpt[this.labelShow] ? findOpt : '.');
        }
          
      });
      return arrayFields.length > 0 ? arrayFields.splice(0, this.max): [];
    }
  }

  // assignValueTriggerStarts(fields) {
  //   if (fields?.length > 0 && this._options?.length > 0 && this.optionTrigger?.length === 0) {
  //     fields?.forEach(field => {
  //       const findOpt = this._options?.find(res => res['name'] ? res['name'] === field : res === field);
  //       if (findOpt) {
  //         this.optionTrigger.push(findOpt);
  //       }
  //     });
  //   }
  // }
 
  getCountTrigger(fields) {
    return fields?.length ? (fields.length - this.max) : 0;
  }

  returnWidthTrigger(fields): string {
    const addMax = fields?.length > this.max ? 26 : 0;
    let minifyItems = 1, marginItems = 1;
    if (fields?.length) {
      minifyItems = fields.length > this.max ? this.max : fields.length;
      marginItems = fields.length > 1 ? 2.5 : 1;
    }
    return `calc(${100 / minifyItems}% - ${(addMax / minifyItems) + (minifyItems * marginItems)}px)`;
  }

  onSelectClosed() {
    if(!!this._value){
      this.closeMenuEmmiter.emit(this._value.indexOf('all')  !== -1? 
      this._options.map(element=>element?.name) : this._value)
      if(!this.isTableFilter){
        this.filterEvent.emit(this._value)
      }

    }

  }

handleResponse(res){
  if (this.hasCount) {
    this._options = res.rows 
  } else {
    this._options = res;
  }
  this.optionEmmiter.emit(this._options);
  this.inputSearchEmitter.emit(this.formAux.value);
  this.loading = false;

}

disableTooltip(element: MatOption): boolean {
  const hostElement = element?._getHostElement();

  if (hostElement?.children?.length > 1) {
    const secondChild = hostElement?.children[1] as HTMLElement;
    return secondChild?.scrollWidth === secondChild?.clientWidth;
  }
    return true;
}

handleMessage(event){
  if (event.length > 1){
    return this?._value[0]
  }
  return event
}



selectAllOptions(){
  if (this.allSelected && this.hasAllSelect) {
    this.multiSelect.options.forEach((item: MatOption) => {
      if (item.value) {
        item.select();
      }
    });
  } else {
    this.multiSelect.options.forEach((item) => item.deselect());
  }
}

checkAllSelectedStatus(event){
  if (event) {
    setTimeout(() => {
      const activeOptions = this.multiSelect.panel.nativeElement.querySelectorAll('.mat-active');
      activeOptions.forEach((option: HTMLElement) => {
        option.classList.remove('mat-active');
      });
    });
  }
  let newStatus = true;
  this.multiSelect.options.forEach((item: MatOption) => {
    if (!item.selected && !!item.value) {
      newStatus = false;
    }
  });
  this.allSelected = newStatus;

}

clickOptFilter() {
  if (this.isTableFilter) {
    if (this._value.indexOf("allOpt") !== -1) {
      if (this._options.length + 1 !== this._value.length) {
        this.writeValue(this.value.filter((res) => res !== "allOpt"));
      }
    } else {
      if (this._oprtionOriginal.length === this._value.length) {
        this.writeValue([...this._value, "allOpt"]);
      }
    }
  }
}


}
